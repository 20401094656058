// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { MAX_LENGTH, REDUCER_STATUS } from '#constants';

import PresentationDetailsTable from '../Tables/PresentationDetailsTable.component';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PresentationDetailsPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const onCancelOrExit = props.onCancelOrExit;
  const presentations = props.presentations;
  const presentationRequest = props.presentationRequest;

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xl'>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('pr-details-credentials')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line'}} >
        <SpinnerLoader text={translate('admin-prt-popup-sending')}>
          <Grid container direction='column' >
            <Grid item='true' paddingLeft={5} paddingTop={6} alignItems={'center'}>
              <Typography fontWeight='bold' fontSize='1.4rem'>
                {translate('attach-credential-pr')}
              </Typography>
            </Grid>
            <PresentationDetailsTable
              translate={translate}
              presentations={presentations}
              presentationRequest={presentationRequest}
            />
            <Grid item='true' paddingBottom='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={onCancelOrExit}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('pr-detail-button-close')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
