// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { clearCustomerCache } from '../../../store/reducers/customers';
import { setError } from '../../../store/reducers/error';
import { CUSTOMER_STATUS, PLATFORM_TYPE } from '#constants';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';

import services from '../../../services';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function NewCorporateContactPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;

  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [did, setDid] = useState(null);
  const [email, setEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [alias, setAlias] = useState(null);
  const [cif, setCif] = useState(null);

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (formSendState) return;

    setDid(null);
    setEmail(null);
    setBusinessName(null);
    setAlias(null);
    setCif(null);
    setIsOpen(false);
  };

  const isFormValid = () => {
    return did && email && businessName && alias && cif;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    const form = {
      did,
      comms: PLATFORM_TYPE[0],
      email,
      businessName,
      alias,
      cif,
      status: CUSTOMER_STATUS[1]
    };

    setFormSendState(true);

    await services.orchestrator.addCustomer(form, setErrorPopup);

    setFormSendState(false);
    dispatch(clearCustomerCache());
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('corporate-contact-popup-new-corporate-contact')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('corporate-contact-popup-sending')} isLoading={formSendState}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('corporate-contact-popup-did')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={did}
                  placeholder={translate('corporate-contact-popup-did-placeholder')}
                  onChange={(event) => setDid(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('corporate-contact-popup-company-name')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={businessName}
                  placeholder={translate('corporate-contact-popup-company-name-placeholder')}
                  onChange={(event) => setBusinessName(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('corporate-contact-popup-alias')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={alias}
                  placeholder={translate('corporate-contact-popup-alias-placeholder')}
                  onChange={(event) => setAlias(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('corporate-contact-popup-email')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={email}
                  placeholder={translate('corporate-contact-popup-email-placeholder')}
                  onChange={(event) => setEmail(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('corporate-contact-popup-cif')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={cif}
                  placeholder={translate('corporate-contact-popup-cif-placeholder')}
                  onChange={(event) => setCif(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('corporate-contact-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('corporate-contact-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
