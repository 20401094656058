import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Hider from '../../components/shared/Hider/Hider.component';
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import DehazeIcon from '@mui/icons-material/Dehaze';

import './Main.layout.css';
import { TroubleshootTwoTone } from '@mui/icons-material';

import SidePanel from '../../components/private/SidePanel/SidePanel.component';

export default function MainLayout(props) {
  const header = props.header;
  const sidePanel = props.sidePanel;
  const noSidePanel = props.noSidePanel;
  const sidebarBackgroundColor = props.sidebarBackgroundColor;
  const layoutSize = props.layoutSize;
  const [sideMenuHide, setSideMenuHide] = useState(layoutSize === 'xsOrS' ? true : false)
  const [popoverMenu, setPopoverMenu] = useState(false)

  useEffect(() => {
    setSideMenuHide(layoutSize === 'xsOrS' ? true : false)
    if(layoutSize !== 'xsOrS') {
      setPopoverMenu(false)
    }
  },[layoutSize])

  return (
    <Container id={'main-layout-container-l0'} disableGutters={true} maxWidth={'100vw'} >
      <Grid id={'main-layout-grid-l0'} container height={'100vh'}>
        <Popover id={'main-layout-popover'} open={popoverMenu} anchorOrigin={{vertical: 'top', horizontal: 2}}>
            <div style={{backgroundColor: sidebarBackgroundColor, paddingBottom: '40px'}}>
                <SidePanel id={'side-0'} fixedMenu={true} translator={props.translator} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize} onNavigation={() => {setPopoverMenu(false)}}/>
            </div>
        </Popover>
        <Grid id={'main-layout-grid-l1'} size={{ s: 0, m: 3, l: 2}} maxWidth={layoutSize === 'lOrXl' ? '400px' : 'null'} style={{backgroundColor: sidebarBackgroundColor}} >
          <Hider isHidden={sideMenuHide}>
            <div style={{backgroundColor: sidebarBackgroundColor}}>
              <div className='admin-layout-side-panel' style={{backgroundColor: sidebarBackgroundColor}}>
                <div>{sidePanel}</div>
              </div>
            </div>
          </Hider>
        </Grid>
        <Grid size={{ s: 12, m: 9, l: 10}} >
          {layoutSize === 'xsOrS' && 
          <div style={{display: 'flex'}}>
            <IconButton sx={{paddingRight: 'calc(100vw-330px)'}} onClick={() => {setPopoverMenu(true)}}><DehazeIcon/></IconButton>
            <div id={'main-layout-xs-header-container'} >{header}</div>
          </div>}
          {layoutSize !== 'xsOrS' && <div style={{ paddingLeft: '2em', paddingRight: '2em'}}>{header}</div>}
          <Divider sx={{ borderColor: '#00000033' }} />
          <div id={'main-layout-outlet-container'}>
            <Outlet />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
