import { Fragment } from 'react';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import ViewsLayout from '../../views/Main/MainView.layout';
import Hider from '../../components/shared/Hider/Hider.component';

const DEFAULT_PAGE_TITLE = '';
const DEFAULT_BUTTONS = [];
const DEFAULT_PRIMARY_TABLE_COMPONENT = Fragment;
const DEFAULT_PRIMARY_TABLE_PROPS = {
  hidePagination: true
};
const DEFAULT_SECONDARY_TABLE_TITLE = '';
const DEFAULT_SECONDARY_TABLE_COMPONENT = Fragment;
const DEFAULT_SECONDARY_TABLE_PROPS = {};
const DEFAULT_HIDE_TERTIARY_TABLE = false;
const DEFAULT_TERTIARY_TABLE_TITLE = '';
const DEFAULT_TERTIARY_TABLE_COMPONENT = Fragment;
const DEFAULT_TERTIARY_TABLE_PROPS = {};

export default function VisualizerLayout(props) {
  const pageTitle = props.pageTitle || DEFAULT_PAGE_TITLE;
  const buttons = props.buttons || DEFAULT_BUTTONS;
  const PrimaryTableComponent = props.primaryTableComponent || DEFAULT_PRIMARY_TABLE_COMPONENT;
  const primaryTableProps = { ...DEFAULT_PRIMARY_TABLE_PROPS, ...props.primaryTableProps };
  const secondaryTableTitle = props.secondaryTableTitle || DEFAULT_SECONDARY_TABLE_TITLE;
  const SecondaryTableComponent = props.secondaryTableComponent || DEFAULT_SECONDARY_TABLE_COMPONENT;
  const secondaryTableProps = { ...DEFAULT_SECONDARY_TABLE_PROPS, ...props.secondaryTableProps };
  const hideTertiaryTable = props.hideTertiaryTable || DEFAULT_HIDE_TERTIARY_TABLE;
  const tertiaryTableTitle = props.tertiaryTableTitle || DEFAULT_TERTIARY_TABLE_TITLE;
  const TertiaryTableComponent = props.tertiaryTableComponent || DEFAULT_TERTIARY_TABLE_COMPONENT;
  const tertiaryTableProps = { ...DEFAULT_TERTIARY_TABLE_PROPS, ...props.tertiaryTableProps };
  const layoutSize = props.layoutSize;

  const secondaryTableRendered = (
    <Grid direction='column' alignItems='stretch' spacing={3}>
      <Grid item='true'>
        <Typography color='textPrimary' variant='h5' fontWeight='bold' fontSize='1.25rem'>
          {secondaryTableTitle}
        </Typography>
      </Grid>

      <SecondaryTableComponent {...secondaryTableProps} />
    </Grid>
  );

  return (
    <ViewsLayout pageTitle={pageTitle} buttons={buttons} layoutSize={layoutSize}>
      <PrimaryTableComponent key={1} {...primaryTableProps} />

      <Grid item='true'>
        <Hider isHidden={!hideTertiaryTable}>{secondaryTableRendered}</Hider>
        <Hider isHidden={hideTertiaryTable}>
          <Grid justifyContent='space-between' spacing={3}>
            <Grid item='true' md={12}>{secondaryTableRendered}</Grid>

            <Grid item='true' md={6}>
              <Grid direction='column' alignItems='stretch' spacing={3}>
              <Grid item='true'>
                  <Typography color='textPrimary' variant='h5' fontWeight='bold' fontSize='1.25rem'>
                    {tertiaryTableTitle}
                  </Typography>
                </Grid>

                <TertiaryTableComponent {...tertiaryTableProps} />
              </Grid>
            </Grid>
          </Grid>
        </Hider>
      </Grid>

      {props.children}
    </ViewsLayout>
  );
}
