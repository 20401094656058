import './Login.view.css';

import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setSession } from '../../../store/reducers/session';
import config from '#config';

export default function Login(props) {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get('access_token');
  const idToken = searchParams.get('id_token');
  if(config.mocked) return (<Navigate to={config.constants.PAGE_ROUTES.EntityCredentials} />)


  if (!accessToken || !idToken) {
    window.location.href = config.API.AUTH_SERVER_URL + '/login';
    return null;
  }

  dispatch(setSession({ accessToken, idToken }));

  return <Navigate to={config.constants.PAGE_ROUTES.EntityCredentials} />
/*
  return (
    <LoginComponent translator={translate} setErrorPopup={setErrorPopup} uris={authServer.URIS} />
  );*/
}