import React, { useState } from "react";
import Container from '@mui/material/Container';

//SLIDES
import LandingSlide from './Slides/Landing/Landing.slide'
import BenefitsSlide from './Slides/Benefits/Benefits.slide'
import ParticipantsSlide from './Slides/Participants/Participants.slide'

//LOGIN
import Login from './Login/Login.view'

//CSS
import './Landing.view.css'

export default function Landing(props) {
  const [errorPopup,setErrorPopup] = useState(false);
  const translator = props.translator;
  const changeLanguage = props.changeLanguage;
  const layoutSize = props.layoutSize;

  return (
    <Container disableGutters={true}>
      <div>
        <div>
          <LandingSlide translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize}/>
        </div>
        <div id={'benefits-slife-container'} style={{position: 'relative', bottom: 'min(-100vh, -700px)'}}>
          <BenefitsSlide translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize}/>
        </div>
        <div id={'particpants-slife-container'} style={{position: 'relative', bottom: 'min(-200vh, -1500px)'}}>
          <ParticipantsSlide translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize}/>
        </div>
      </div>
    </Container>
  );
}