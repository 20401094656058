// ROUTER
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import config from '#config';

// WHOAMI
// import { PAGE_ROUTES } from '../constants';

// LAYOUTS
// import PrimaryLayout from '../layouts/Primary/Primary.layout';
import LandingLayout from '../layouts/Landing/Landing.layout';
import MainLayout from '../layouts/Main/Main.layout';

// VIEWS
// import Error404 from '../views/ErrorPages/Error404.view';
import Landing from '../views/landing/Landing.view';
import Login from '../views/landing/Login/Login.view';
import LoginComponent from '../components/private/LoginForms/Login.component'

import NotificationInbox from '../views/Main/NotificationInbox/NotificationInbox.view';

import EntityPresentationRequests from '../views/Main/Entity/PresentationRequests/EntityPresentationRequests.view';
import PRVisualizerEntity from '../views/Main/Entity/PresentationRequests/Visualize/PRVisualizer.view';
import EntityCredentialRequests from '../views/Main/Entity/CredentialRequests/EntityCredentialRequests.view';
import EntityPresentations from '../views/Main/Entity/Presentations/EntityPresentations.view';
import EntityCredentials from '../views/Main/Entity/Credentials/EntityCredentials.view';

import PresentationRequestHistory from '../views/Main/Admin/PresentationRequestHistory/PresentationRequestHistory.view';
import PRVisualizer from '../views/Main/Admin/PresentationRequestHistory/Visualize/PRVisualizer.view';
import AdminCredentialRequests from '../views/Main/Admin/CredentialRequests/AdminCredentialRequests.view';
import AdminCredentials from '../views/Main/Admin/Credentials/AdminCredentials.view';
import AdminPresentationRequestTemplates from '../views/Main/Admin/PresentationRequestTemplates/PresentationRequestTemplates.view';
import PRTVisualizer from '../views/Main/Admin/PresentationRequestTemplates/Visualize/PRTVisualizer.view';
import PRCreation from '../views/Main/Admin/PresentationRequestTemplates/Visualize/PRCreation.view'

import CorporateContacts from '../views/Main/Contacts/Corporate/Corporate.view';
import IndividualContacts from '../views/Main/Contacts/Individual/Individual.view';

// COMPONENTS
import Error from '@mui/icons-material/Error';

import Header from '../components/private/Header/Header.component';
import SidePanel from '../components/private/SidePanel/SidePanel.component';
// import DigitalAssets from '../views/DigitalAssets/DigitalAssets.view';

// Documentation on how the router works is available here:
// https://reactrouter.com/en/main/routers/create-browser-router

export default function DataRouter(props) {
  const translator = props.translator;
  const changeLanguage = props.changeLanguage;
  const sidebarBackgroundColor = props.sidebarBackgroundColor;
  const layoutSize = props.layoutSize;

  return (
    <RouterProvider
      router={createBrowserRouter([
        // LANDING LAYOUT
        {
          path: '/',
          element: <LandingLayout translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
          children: [
            {
              element: <Landing translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true,
            },
          ],
        },
        {
          path: config.constants.PAGE_ROUTES.Login,
          element: <LandingLayout translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
          children: [
            {
              element: <Login translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true,
            },
            {
              path: config.constants.PAGE_ROUTES.LoginForm,
              element: <LoginComponent translator={translator} layoutSize={layoutSize}/>,
              index: true
            }
          ],
        },
        // MAIN LAYOUT
        // MAIN LAYOUT - NOTIFICATIONS
        {
          path: config.constants.PAGE_ROUTES.Notifications,
          element: <MainLayout translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} sidebarBackgroundColor={sidebarBackgroundColor} header={<Header translator={translator} changeLanguage={changeLanguage} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} sidePanel={<SidePanel fixedMenu={false} translator={translator} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} />,
          children: [
            // MAIN LAYOUT - MY CREDENTIALS - RECEIVED REQUESTS
            {
              path: config.constants.PAGE_ROUTES.NotificationInbox,
              element: <NotificationInbox translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            }
          ]
        },
        // MAIN LAYOUT - MY CREDENTIALS
        {
          path: config.constants.PAGE_ROUTES.Entity,
          element: <MainLayout translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} sidebarBackgroundColor={sidebarBackgroundColor} header={<Header translator={translator} changeLanguage={changeLanguage} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} sidePanel={<SidePanel fixedMenu={false} translator={translator} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} />,
          children: [
            // MAIN LAYOUT - MY CREDENTIALS - RECEIVED REQUESTS
            {
              path: config.constants.PAGE_ROUTES.EntityPresentationRequests,
              children: [
                {
                  element: <EntityPresentationRequests translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
                  index: true
                },
                {
                  path: `${config.constants.PAGE_ROUTES.EntityPresentationRequests}/:cdi`,
                  element: <PRVisualizerEntity translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize}/>,
                }
              ]
            },
            // MAIN LAYOUT - MY CREDENTIALS - ADD CREDENTIALS
            {
              path: config.constants.PAGE_ROUTES.EntityCredentialRequests,
              element: <EntityCredentialRequests translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            },
            // MAIN LAYOUT - MY CREDENTIALS - SHARED
            {
              path: config.constants.PAGE_ROUTES.EntityPresentations,
              element: <EntityPresentations translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            },
            // MAIN LAYOUT - MY CREDENTIALS - CREDENTIALS STATUS
            {
              path: config.constants.PAGE_ROUTES.EntityCredentials,
              element: <EntityCredentials translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            },
          ]
        },
        // MAIN LAYOUT - ADMIN
        {
          path: config.constants.PAGE_ROUTES.Admin,
          element: <MainLayout translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} sidebarBackgroundColor={sidebarBackgroundColor} header={<Header translator={translator} changeLanguage={changeLanguage} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} sidePanel={<SidePanel fixedMenu={false} translator={translator} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} />,
          children: [
            // MAIN LAYOUT - ADMIN - SENT REQUESTS
            {
              path: config.constants.PAGE_ROUTES.AdminPresentationRequestHistory,
              children: [
                {
                  element: <PresentationRequestHistory translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
                  index: true
                },
                {
                  path: `${config.constants.PAGE_ROUTES.AdminPresentationRequestHistory}/:cdi`,
                  element: <PRVisualizer translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize}/>,
                }
              ]
            },
            // MAIN LAYOUT - ADMIN - MINT QUEUE
            {
              path: config.constants.PAGE_ROUTES.AdminCredentialRequests,
              element: <AdminCredentialRequests translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            },
            // MAIN LAYOUT - ADMIN - MINTED CREDENTIALS
            {
              path: config.constants.PAGE_ROUTES.AdminCredentials,
              element: <AdminCredentials translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            },
            // MAIN LAYOUT - ADMIN - FORMS
            {
              path: config.constants.PAGE_ROUTES.AdminPresentationRequestTemplates,
              children: [
                {
                  element: <AdminPresentationRequestTemplates translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
                  index: true
                },
                {
                  path: `${config.constants.PAGE_ROUTES.AdminPresentationRequestTemplates}/:cdi`,
                  element: <PRTVisualizer translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize}/>,
                }
              ]
            },
            {
              path: config.constants.PAGE_ROUTES.AdminPresentationRequestCreation,
              element: <PRCreation translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} />,
              index: true
            },
          ]
        },
        // MAIN LAYOUT - CONTACTS
        {
          path: config.constants.PAGE_ROUTES.Contacts,
          element: <MainLayout translator={translator} changeLanguage={changeLanguage} layoutSize={layoutSize} sidebarBackgroundColor={sidebarBackgroundColor} header={<Header translator={translator} changeLanguage={changeLanguage} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} sidePanel={<SidePanel fixedMenu={false} translator={translator} sidebarBackgroundColor={sidebarBackgroundColor} layoutSize={layoutSize}/>} />,
          children: [
            // MAIN LAYOUT - CONTACTS - ENTITIES
            {
              path: config.constants.PAGE_ROUTES.ConctactsCorporate,
              element: <CorporateContacts translator={translator} layoutSize={layoutSize}/>,
              index: true
            },
            // MAIN LAYOUT - CONTACTS - USERS
            {
              path: config.constants.PAGE_ROUTES.ContactsIndividuals,
              element: <IndividualContacts translator={translator} layoutSize={layoutSize}/>,
            },
          ]
        },
      ])}
    />
  );
}
