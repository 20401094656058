// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { clearCustomerCache } from '../../../store/reducers/customers';
import { setError } from '../../../store/reducers/error';
import { CUSTOMER_STATUS, PLATFORM_TYPE } from '#constants';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';

import services from '../../../services';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function ConnectIndividualContactPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;

  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [did, setDid] = useState(null);
  const [email, setEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [alias, setAlias] = useState(null);
  const [phone, setPhone] = useState(null);
  const [prefix, setPrefix] = useState(null);

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (formSendState) return;

    setIsOpen(false);
  };

  const isFormValid = () => {
    return did && email && businessName && alias && prefix && phone;
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    const form = {
      did,
      comms: PLATFORM_TYPE[1],
      email,
      businessName,
      alias,
      tel: `+${prefix}${phone}`,
      status: CUSTOMER_STATUS[1]
    };

    setFormSendState(true);

    await services.orchestrator.addCustomer(form, setErrorPopup);

    setFormSendState(false);
    dispatch(clearCustomerCache());
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('individual-contact-connect-popup-title')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('individual-contact-connect-popup-sending')} isLoading={formSendState}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('individual-contact-connect-popup-did')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={did}
                  placeholder={translate('individual-contact-connect-popup-did-placeholder')}
                  onChange={(event) => setDid(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('individual-contact-connect-popup-name')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={businessName}
                  placeholder={translate('individual-contact-connect-popup-name-placeholder')}
                  onChange={(event) => setBusinessName(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('individual-contact-connect-popup-surname')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={alias}
                  placeholder={translate('individual-contact-connect-popup-surname-placeholder')}
                  onChange={(event) => setAlias(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('individual-contact-connect-popup-email')}
                  </Typography>
                </Grid>
                <TextField
                  required
                  value={email}
                  placeholder={translate('individual-contact-connect-popup-email-placeholder')}
                  onChange={(event) => setEmail(event.target.value)}
                  sx={{
                    height: '2.5rem',
                    width: '22.125rem',
                    backgroundColor: 'white',
                    border: '1px solid #E5E5E5',
                    borderRadius: '0.313rem',
                    fontSize: '0.938rem',
                    '.MuiInputBase-root': {
                      height: '2.5rem',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5'
                      }
                    },
                    '&.Mui-focused': {
                      border: 'none'
                    },
                    '&.MuiOutlinedInput-root:hover': {
                      border: 'none'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('individual-contact-connect-popup-phone')}
                  </Typography>
                </Grid>
                <Grid container>
                  <TextField
                    required
                    value={prefix}
                    type='number'
                    placeholder={translate('individual-contact-connect-popup-phone-prefix-placeholder')}
                    onChange={(event) => setPrefix(event.target.value)}
                    sx={{
                      height: '2.5rem',
                      width: '5rem',
                      backgroundColor: 'white',
                      border: '1px solid #E5E5E5',
                      borderRadius: '0.313rem',
                      fontSize: '0.938rem',
                      '.MuiInputBase-root': {
                        height: '2.5rem',
                        '&:hover': {
                          border: '1.25px solid #E5E5E5'
                        }
                      },
                      '&.Mui-focused': {
                        border: 'none'
                      },
                      '&.MuiOutlinedInput-root:hover': {
                        border: 'none'
                      }
                    }}
                  />
                  <TextField
                    required
                    value={phone}
                    placeholder={translate('individual-contact-connect-popup-phone-placeholder')}
                    onChange={(event) => setPhone(event.target.value)}
                    sx={{
                      height: '2.5rem',
                      width: '17.125rem',
                      backgroundColor: 'white',
                      border: '1px solid #E5E5E5',
                      borderRadius: '0.313rem',
                      fontSize: '0.938rem',
                      '.MuiInputBase-root': {
                        height: '2.5rem',
                        '&:hover': {
                          border: '1.25px solid #E5E5E5'
                        }
                      },
                      '&.Mui-focused': {
                        border: 'none'
                      },
                      '&.MuiOutlinedInput-root:hover': {
                        border: 'none'
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('individual-contact-connect-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('individual-contact-connect-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
