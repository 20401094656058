// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import { CREDENTIAL_REQUEST_STATUS, CREDENTIAL_STATUS } from '#constants';

import SearchBar from '../../shared/SearchBars/SearchBar.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_USERS = [];
const DEFAULT_IS_REQUEST = false;
const DEFAULT_IS_LOADING = false;
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_CUSTOMER = '';
const DEFAULT_SET_CUSTOMER = () => {};
const DEFAULT_STATUS = '';
const DEFAULT_SET_STATUS = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_REFRESH = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CredentialFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const users = props.users || DEFAULT_USERS;
  const isRequest = props.isRequest || DEFAULT_IS_REQUEST;
  const isLoading = props.isLoading || DEFAULT_IS_LOADING;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const customer = props.customer || DEFAULT_CUSTOMER;
  const setCustomer = props.setCustomer || DEFAULT_SET_CUSTOMER;
  const status = props.status || DEFAULT_STATUS;
  const setStatus = props.setStatus || DEFAULT_SET_STATUS;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const refresh = props.refresh || DEFAULT_REFRESH;

  const HandlerRefresh = () => {
    setCustomer('');
    setSearch('');
    setStatus('');

    refresh();
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      className='credentials-filters-container'>
      <Grid item='true'>
        <SearchBar
          placeholder={translate('credentials-filter-search-credential')}
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
        />
      </Grid>

      <Dropdown
        value={customer}
        isLoading={isLoading}
        options={users}
        getOptionLabel={(customer) => customer.businessName}
        getOptionValue={(customer) => customer.did}
        onChange={(customer) => {
          setCustomer(customer);
          onFilterSubmit();
        }}
        label={translate('credentials-page-filter-issuer')}
      />

      <Dropdown
        value={status}
        options={(isRequest ? CREDENTIAL_REQUEST_STATUS : CREDENTIAL_STATUS).map((status) => {
          return { status };
        })}
        getOptionLabel={(status) =>
          translate(`credentials-page-filter-${isRequest ? 'request' : 'emission'}-${status.status.toLowerCase()}`)
        }
        getOptionValue={(status) => status.status}
        onChange={(status) => {
          setStatus(status);
          onFilterSubmit();
        }}
        label={translate('credentials-page-filter-status')}
      />

      <Grid item='true'>
        <Button
          color='primary'
          variant='contained'
          sx={{
            height: '2.5rem',
            textTransform: 'none'
          }}
          onClick={HandlerRefresh}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
            {translate('credentials-page-refresh')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
