import { createTheme } from '@mui/material';

export const transportTheme = createTheme({
  palette: {
    primary: {
      main: '#857E50'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#857E50',
      secondary: '#666666'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});

export const b4eTheme = createTheme({
  palette: {
    primary: {
      main: '#1E1E56'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#1E1E56',
      secondary: '#666666'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});


export const repsolTheme = createTheme({
  palette: {
    primary: {
      main: '#FF8200'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#FF8200',
      secondary: '#666666'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});

export const sybolTheme = createTheme({
  default: true,
  palette: {
    primary: {
      main: '#211951'
    },
    secondary: {
      main: '#FAFAFA'
    },
    text: {
      primary: '#211951',
      secondary: '#000000'
    }
  },
  typography: {
    fontFamily: '"Open Sans"'
  },
  breakpoints: {
    values: {
      s: 0,
      m: 800,
      l: 1400,
    },
  }
});