// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../../store/reducers/customers';
import {
  clearCredentialRequestsCache,
  fetchCredentialRequestsAsync,
  selectCredentialRequests,
  selectCredentialRequestsCount,
  selectCredentialRequestsPage,
  selectCredentialRequestsState,
  selectCredentialRequestsRowsPerPage
} from '../../../../store/reducers/adminCredentialRequests';

import { CUSTOMER_STATUS, REDUCER_STATUS } from '#constants';

import CredentialFilters from '../../../../components/private/Filters/CredentialFilters.component';
import CredentialTable from '../../../../components/private/Tables/CredentialTable.component';
import AdminCredentialRequestButtons from '../../../../components/private/Dropdowns/AdminCredentialRequestButtons';

import ViewsLayout from '../../MainView.layout';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function AdminCredentialRequests(props) {
  const translate = props.translator;
  const layoutSize = props.layoutSize;
  const dispatch = useDispatch();

  const credentialRequests = useSelector(selectCredentialRequests);
  const credentialRequestsState = useSelector(selectCredentialRequestsState);
  const credentialRequestsCount = useSelector(selectCredentialRequestsCount);

  const customers = useSelector(selectCustomers);
  const customerState = useSelector(selectCustomersState);

  const [search, setSearch] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(useSelector(selectCredentialRequestsPage));
  const [rowsPerPage, setRowsPerPage] = useState(useSelector(selectCredentialRequestsRowsPerPage));

  const isLoadingCredentials = credentialRequestsState === REDUCER_STATUS.LOADING;
  const credentialsNeedsReload = credentialRequestsState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoadingUsers = customerState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = customerState === REDUCER_STATUS.NEEDS_REFRESH;

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (credentialsNeedsReload) {
    dispatch(
      fetchCredentialRequestsAsync({
        page,
        rowsPerPage,
        search,
        customerId: customer ? customer.did : undefined,
        status: status ? status.status : undefined
      })
    );
  }

  const onFilterSubmit = () => {
    setPage(0);
    dispatch(clearCredentialRequestsCache());
  };

  const handleSetPage = (newPage) => {
    setPage(newPage);
    dispatch(clearCredentialRequestsCache());
  };

  const handleSetRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    dispatch(clearCredentialRequestsCache());
  };

  return (
    <ViewsLayout pageTitle={translate('admin-credential-requests-page-title')} layoutSize={layoutSize}>
      <Grid item='true'>
        <CredentialFilters
          translate={translate}
          users={customers}
          isRequest={true}
          isLoading={isLoadingUsers}
          search={search}
          setSearch={setSearch}
          customer={customer}
          setCustomer={setCustomer}
          status={status}
          setStatus={setStatus}
          onFilterSubmit={onFilterSubmit}
          refresh={onFilterSubmit}
        />
      </Grid>

      <CredentialTable
        translate={translate}
        isLoading={isLoadingCredentials}
        credentials={credentialRequests}
        credentialCount={credentialRequestsCount}
        isCredentialRequests={true}
        page={page}
        setPage={handleSetPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleSetRowsPerPage}
        hideLevelOfAssurance={true}
        translatePrefix={'admin-credential-request-table'}
        actionsComponent={AdminCredentialRequestButtons}
      />
    </ViewsLayout>
  );
}
