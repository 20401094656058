// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// ------FILE MODULES---------------------------------------------------------
import SearchBar from '../../shared/SearchBars/SearchBar.component';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_WIDTH = '16.563rem';
const DEFAULT_SEARCH = '';
const DEFAULT_SET_SEARCH = () => {};
const DEFAULT_ON_FILTER_SUBMIT = () => {};
const DEFAULT_REFRESH = () => {};
const DEFAULT_OPEN_ADVANCED = () => {};

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CatalogFilters(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const width = props.width || DEFAULT_WIDTH;
  const search = props.search || DEFAULT_SEARCH;
  const setSearch = props.setSearch || DEFAULT_SET_SEARCH;
  const onFilterSubmit = props.onFilterSubmit || DEFAULT_ON_FILTER_SUBMIT;
  const refresh = props.refresh || DEFAULT_REFRESH;
  const openAdvanced = props.openAdvanced || DEFAULT_OPEN_ADVANCED;

  const style = { width };

  const HandlerRefresh = () => {
    setSearch('');

    refresh();
  }

  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' paddingBottom={2}>
      <Grid item='true'>
        <SearchBar
          placeholder={translate('catalog-table-filters-search')}
          translate={translate}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onFilterSubmit();
          }}
          onSubmit={onFilterSubmit}
          style={style}
        />
      </Grid>

      <Grid item='true'>
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid item='true'>
            <Button
              color='secondary'
              variant='contained'
              sx={{
                border: '1px solid #E5E5E5',
                fontSize: '0.938rem',
                height: '2.5rem',
                fontWeight: '600',
                boxShadow: 'none',
                textTransform: 'none',
                '&:hover': {
                  border: '1.25px solid #E5E5E5',
                  boxShadow: 'none'
                }
              }}
              onClick={() => {
                openAdvanced();
              }}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
                {translate('catalog-table-filters-advanced')}
              </Typography>
            </Button>
          </Grid>

          <Grid item='true'>
            <Button
              color='primary'
              variant='contained'
              sx={{
                height: '2.5rem',
                textTransform: 'none'
              }}
              onClick={HandlerRefresh}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }} paddingRight={1} paddingLeft={1}>
                {translate('catalog-table-filters-refresh')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
