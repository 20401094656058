// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { fetchPRTAsync, selectPresentationRequestTemplates, selectPresentationRequestTemplatesState } from '../../../store/reducers/presentationRequestTemplates'

import {
  REDUCER_STATUS,
} from '#constants';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';

import services from '../../../services';

const DEFAULT_PRESENTATION_REQUEST_TEMPLATES = [];


async function getPrtData(presentationRequestTemplate) {

  const prts = await Promise.all(presentationRequestTemplate.map(async (presentationRequestTemplate) => {

    let prt = await services.orchestrator.getPresentationRequestTemplate(presentationRequestTemplate.prtId);
    prt.data = JSON.parse(prt.data);
    if (prt.data.credentials) {
      prt.data.credentials = await Promise.all(
        await Promise.all(prt.data.credentials.map(async (credential) => {
          const catalogEntry = await services.orchestrator.getCredentialFromCatalog(credential.credentialContext);
          return { ...credential, ...(catalogEntry[0]) };
        }))
      );
    }

    prt.data = JSON.stringify(prt.data);
    return prt;
  }));
  
  return prts;
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function CreatePRTCredentialSelection(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;

  const presentationRequestTemplates = useSelector(selectPresentationRequestTemplates)
  const presentationRequestTemplatesState = useSelector(selectPresentationRequestTemplatesState)

  const dispatch = useDispatch();

  const selectedPresentationRequestTemplates = props.selectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
  const setSelectedPresentationRequestTemplates = props.setSelectedPresentationRequestTemplates || DEFAULT_PRESENTATION_REQUEST_TEMPLATES;
 
  const isLoadingPresentationRequestTemplates = presentationRequestTemplatesState === REDUCER_STATUS.LOADING;
  const presentationRequestTemplatesNeedsReload = presentationRequestTemplatesState === REDUCER_STATUS.NEEDS_REFRESH;

  if (presentationRequestTemplatesNeedsReload) {
    dispatch(fetchPRTAsync({  limit: null }));
  }

  const handleClose = () => {
    setSelectedPresentationRequestTemplates(selectedPresentationRequestTemplates);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('presentation-request-selection-popup-title')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}          style={{

    }}>
        <SpinnerLoader text={translate('presentation-request-send-popup-sending')}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px'>
              <Grid container spacing={4}>
                <Grid item='true' paddingTop='10px'>
                  <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                    <Grid item='true'>
                      <Typography fontWeight='bold' fontSize='0.938rem'>
                        {translate('presentation-request-selection-popup-subtitle')}
                      </Typography>
                    </Grid>
                    <Dropdown
                      label={translate('presentation-request-selection-popup-subtitle')}
                      value={selectedPresentationRequestTemplates}
                      required
                      multivalue
                      isLoading={isLoadingPresentationRequestTemplates}
                      options={presentationRequestTemplates}
                      getOptionLabel={(presentationRequestTemplate) => JSON.parse(presentationRequestTemplate.data).title}
                      getOptionValue={(presentationRequestTemplate) => JSON.parse(presentationRequestTemplate.data).title}
                      onChange={async (presentationRequestTemplate) => {
                        setSelectedPresentationRequestTemplates(await getPrtData(presentationRequestTemplate));
                      }}
                      selectStyle={{
                        width: '22.125rem',
                        borderRadius: '5px',
                        fontSize: '0.938rem'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item='true' paddingTop={20} paddingLeft={45} paddingBottom={1}>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('presentation-request-selection-popup-accept-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}