import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Translate, ArrowForwardIos } from '@mui/icons-material';
import { useLinkClickHandler } from "react-router-dom";
import mainLandingConfig from './Landing.config';
import config from '#config'
import ButtonWithOutline from '../../../../components/shared/Buttons/ButtonWithOutline.component';
import DigitalisIcon from '../../../../components/shared/Icons/DigitalisIcon.component';
import './Landing.slide.css';

export default function Landing(props) {
  const [infotainerIndex, setIndex] = useState(0);

  const translator = props.translator;
  const changeLanguage = props.changeLanguage;
  const layoutSize = props.layoutSize;
  const minHeight = useMediaQuery('(max-height:800px)');

  return (
  <Container id={'lading-slide-l0-container'} sx={{  top: minHeight&&(layoutSize==='xsOrS') ? '1em' : '25%' , position: 'absolute', color: '#f1f1f1', backgroundColor: '#00575CD9', width: '100%', minHeight: '700px'}}>
    <video autoPlay loop muted className='video-background'>
      <source src='/images/landing.mp4' type='video/mp4' />
    </video>
    <Grid id={'lading-slide-l0-grid'} columns={12} container paddingTop={10} paddingBottom={10} paddingRight={'1em'} paddingLeft={layoutSize==='xsOrS' ? 2: 5} maxWidth={'1400px'} width={'95%'}>
        <Grid id={'lading-slide-l10-grid'} container justifyContent='left' alignItems='flex-start' size={{ s: 12, m: 9 , l: 9}}>
          <Grid item='true'>
            <DigitalisIcon />
          </Grid>
          <Grid container direction='column' justifyContent='center' alignItems='flex-start' spacing={5} paddingTop={5}>
            <Grid item='true'>
              <Typography variant='h5'>{translator(mainLandingConfig[infotainerIndex].title)}</Typography>
            </Grid>
            <Grid item='true'>
              <Typography variant='h3'>{translator(mainLandingConfig[infotainerIndex].subtitle)}</Typography>
            </Grid>
            <Grid item='true'>
              <Typography variant='body1'>{translator(mainLandingConfig[infotainerIndex].description)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid id={'lading-slide-l11-grid'} container direction={layoutSize==='xsOrS' ? 'row' : 'column'} justifyContent='flex-start' alignItems='flex-end' size={{ s: 12, m: 3, l: 3}} maxWidth={layoutSize==='xsOrS' ? '500px' : '170px'}>
          <Grid item='true' paddingTop={layoutSize==='xsOrS' ? 10: 0} paddingBottom={layoutSize==='xsOrS' ? 0: 10} paddingRight={layoutSize==='xsOrS' ? 2: 0}>
            <ButtonWithOutline onClick={changeLanguage}>
              <Translate />
            </ButtonWithOutline>
          </Grid>
          <Grid paddingBottom={layoutSize==='xsOrS' ? 0: 3} paddingRight={layoutSize==='xsOrS' ? 2: 0}>
            <Button
              fullWidth
              variant='outlined'
              sx={{
                width: layoutSize==='xsOrS' ? '100px' : '120px',
                color: '#A9D9D0',
                border: '3px solid #A9D9D0',
                '&:hover': { border: '3px solid #19CFAD', color: '#19CFAD' },
              }}
              onClick={useLinkClickHandler(config.constants.PAGE_ROUTES.Login)}
            >
              <Typography
                s={{fontSize: '1em'}}
                m={{fontSize: '1.2em'}}
                l={{fontSize: '1.2em'}}
              >
                {translator('landing-login-button')}
              </Typography>
              <ArrowForwardIos fontSize={'small'}/>
            </Button>
          </Grid>
          <Grid id={'connect-wallet-button'} paddingBottom={layoutSize==='xsOrS' ? 0: 3} paddingRight={layoutSize==='xsOrS' ? 2: 0}>
            <Button
              fullWidth
              variant='outlined'
              sx={{
                width: layoutSize==='xsOrS' ? '100px' : '120px',
                color: '#A9D9D0',
                border: '3px solid #A9D9D0',
                '&:hover': { border: '3px solid #19CFAD', color: '#19CFAD' },
              }}
              onClick={() => {console.log('Conect wallet')}}
            >
              <Typography
                s={{fontSize: '1em'}}
                m={{fontSize: '1.2em'}}
                l={{fontSize: '1.2em'}}
              >
                {translator('landing-connect-wallet-button')}
              </Typography>
              <ArrowForwardIos fontSize={'small'}/>
            </Button>
          </Grid>
          <Grid item='true' paddingBottom={layoutSize==='xsOrS' ? 0: 3} paddingRight={layoutSize==='xsOrS' ? 0: 0}>
            <Button
              fullWidth
              variant='outlined'
              className='register-button'
              sx={{
                width: layoutSize==='xsOrS' ? '100px' : '120px',
                color: '#F2E7DC',
                border: '3px solid #F2E7DC',
                '&:hover': { border: '3px solid #F2C0DC', color: '#F2C0DC' },
              }}
              //onClick={useLinkClickHandler(PAGE_ROUTES.Register)}
            >
              <Typography                   
                s={{fontSize: '0.5em !important'}}
                m={{fontSize: '1.2em'}}
                l={{fontSize: '1.2em'}}
              >{translator('landing-create-button')}</Typography>
              <ArrowForwardIos fontSize={'small'}/>
            </Button>
          </Grid>
      </Grid>
    </Grid>
  </Container>);
}
