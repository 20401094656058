// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------ NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { fetchCorporateAsync, selectWhoAmI, selectWhoAmIStatus } from '#reducers/corporate';

// ------ FILE MODULES ---------------------------------------------------------
import { REDUCER_STATUS, ROW_COUNT } from '#constants';

import PresentationRequestVisualizeTable from '#privateComponents/Tables/PresentationRequestVisualizeTable.component';
import PresentationRequestTable from '#privateComponents/Tables/PresentationRequestTable.component';

import VisualizerLayout from '#layouts/Visualizer/Visualizer.layout';

import services from '../../../../../services';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
async function getPr(cdi, iss, setPr, setIsPrLoading) {
  setIsPrLoading(true);
  const prs = await services.orchestrator.getPresentationRequestAdmin(cdi, iss);
  setPr(prs);
  setIsPrLoading(false);
}

async function getPresentations(cdi, setPresentation, setIsPRFLoading) {
  setIsPRFLoading(true);
  let presentations = await services.orchestrator.getPresentationByCdi(cdi);
  //presentations = presentations.filter((presentation) => presentation.decoded.vp.verifiableCredential[0].decoded === undefined);
  /*let vps = presentations.map((presentation) => { 
    const storedJwt = presentation.storedJwt;
    const decoded = decodeJwt(presentation.storedJwt.signedToken);
    return { storedJwt, decoded };
  });
  let presentationList = (vps.length !== 0) ?
    vps[0].decoded.vp.verifiableCredential.map((vpl) => {
      let decoded = decodeJwt(vpl);
      decoded.vp.verifiableCredential = decoded.vp.verifiableCredential.map((credential) => {
        let decoded2 = decodeJwt(credential);
        decoded2.vc.credentialSubject = JSON.parse(decoded2.vc.credentialSubject);

      return { decoded: decoded2 };
      });
      return { decoded };
    }
    ) : [];*/
  
    console.log(presentations);
    let a = []
    presentations.map((presentation) => {
      if(presentation.decoded.vp.verifiableCredential[0].decoded.vc) {
        a.push(presentation);
      } else {
        presentation.decoded.vp.verifiableCredential.map((vp) => {
          if (vp.decoded.vp.verifiableCredential[0].decoded.vc) {
            a.push(vp);
          }
        })
      }
    });
    console.log(a);
    presentations = a.filter((presentation) => presentation.decoded.vp.verifiableCredential[0].decoded !== undefined);
  setPresentation(presentations);
  setIsPRFLoading(false);

}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function PRVisualizer(props) {
  const translate = props.translator;

  const dispatch = useDispatch();

  const pathParams = useParams();
  const cdi = pathParams.cdi;

  const [pr, setPr] = useState([]);
  const [presentations, setPresentation] = useState([]);
  const [isPrLoading, setIsPrLoading] = useState(undefined);
  const [isPRFLoading, setIsPRFLoading] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROW_COUNT[0]);

  const whoAmI = useSelector(selectWhoAmI);
  const whoAmIStatus = useSelector(selectWhoAmIStatus);

  const whoAmINeedsRefresh = whoAmIStatus === REDUCER_STATUS.NEEDS_REFRESH;
  const whoAmIIsReady = whoAmIStatus === REDUCER_STATUS.READY;

  if (whoAmINeedsRefresh) {
    dispatch(fetchCorporateAsync());
  }

  if (isPrLoading === undefined) {
    getPr(cdi, whoAmI.did, setPr, setIsPrLoading);
  }

  if (isPRFLoading === undefined) {
    getPresentations(cdi, setPresentation, setIsPRFLoading);
  }

  // if (isPrtLoading === undefined) {
  //   //getPrtData(prtId, setPrt, setIsPrtLoading);
  // }

  // const credentials = JSON.parse(prt.data).credentials;
  // let credentialsPaginated = [];
  // let credentialCount = 0;

  // if (credentials) {
  //   credentialCount = credentials.length;
  //   credentialsPaginated = [...credentials].splice(page * rowsPerPage, rowsPerPage);
  // }

  return (
    <VisualizerLayout
      pageTitle={translate('prt-details-page-title')}
      primaryTableComponent={PresentationRequestTable}
      primaryTableProps={{
        translate: translate,
        isLoading: isPrLoading,
        hidePagination: true,
        presentationRequests: pr,
        openPopupInstead: true,
        hideIssuer: true,
        admin: true,
        disableOnClick: true,
        hideActions: true
      }}
      secondaryTableTitle={translate('prt-details-page-title-forms')}
      secondaryTableComponent={PresentationRequestVisualizeTable}
      secondaryTableProps={{
        translate: translate,
        PresentationRequests: pr,
        presentations: presentations,
        page,
        rowsPerPage,
        hideEntityActions: true
      }}
      buttons={[
      ]}>
    </VisualizerLayout>
  );
}